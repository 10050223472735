// -------------- //
// --- HEADER --- //
// -------------- //

// Hamburger icon animation
@use '../utilities/hamburger';
@use '../utilities/variables';
@use 'sass-mq';

#header {
  font-size: variables.rem(16);
  line-height: 1;

  backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba($color: variables.$white, $alpha: 0.9);
}

.header-toplevel {
  border-bottom: 1px solid variables.$light-grey;

  .header-toplevel__content {
    display: flex;
    justify-content: stretch;
    align-items: center;
    color: variables.$brand;

    // The left-side logo needs homoeneous surrounding space, hence 0.5 x 3
    padding: 1rem;
    gap: 1.5rem;

    .filler {
      flex-grow: 1;
    }

    svg {
      fill: variables.$white;
    }
  }
}

// Main nav
#nav {
  font-family: variables.$bold;
  // text-transform: uppercase;
  color: variables.$dark-grey;

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    display: inline;
    margin-right: 1.5rem;
  }

  a:not(.button) {
    color: variables.$dark-grey;
    text-decoration: none;
    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

#logo {
  $logo-width: 45px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: variables.$dark-blue;
  font-family: 'BebasNeue-Regular', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  gap: 0.7rem;

  svg {
    display: block;
    height: $logo-width;
    width: $logo-width;

    path {
      fill: variables.$dark-blue !important;
    }
  }
}

#rss-feed {
  $rss-logo-width: 1.5rem;

  svg {
    display: block;
    height: $rss-logo-width;
    width: $rss-logo-width;
  }
}

#toggle-nav {
  display: none;
}

// Breadcrumb
#breadcrumb {
  padding: 0 variables.em(20, 14);
  line-height: variables.lh(48, 14);

  a,
  span {
    color: variables.$brand;

    &::after {
      content: '>';
      padding-left: variables.em(14, 14);
      padding-right: variables.em(7, 7);
      text-decoration: none;
      display: inline-block;
    }

    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

// Expanded mobile nav
.nav-is-displayed {
  .header-toplevel {
    padding-bottom: 0;
    border-bottom: 0.5rem solid variables.$brand;
  }
  #nav {
    display: block;
    background-color: variables.$grey;
    border-bottom: 2px solid variables.$brand;

    ul {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 1rem;
    }

    a {
      display: block;
      // color: variables.$dark-grey;
    }
  }
}

// --- Mobile & Tablet --- //

@include sass-mq.mq($until: tablet) {
  .header-toplevel {
    // The hamburger menu introduces a lot of extra content height, causing a
    // perceivable height jump; we're compensating with lower block paddings.
    padding-block: 0.25rem;
  }
  #nav {
    display: none;
  }
  // Mobile nav button
  #toggle-nav {
    display: block;
    overflow: hidden;
    position: relative;
    line-height: 1;
    height: variables.em(40, 14);
    // This introduces a slight gap discrepancy, but ensures a neat square
    // rendering when active.
    width: variables.em(40, 14);
    .content {
      position: absolute;
      top: -100px;
      left: -200px;
    }
    .hamburger-box {
      height: inherit;
    }
  }
  // Breadcrumb
  #breadcrumb {
    display: none;
  }
}

// --- Wide --- //

@include sass-mq.mq($from: wide) {
  // #main {
  //   width: variables.em(1400, 18);
  // }
  #header {
    // .header-toplevel__content,
    .inner-nav {
      width: variables.em(1200, 16);
      margin: 0 auto;
    }
  }
}
