@use '../utilities/variables';

// ------------------ //
// --- TOPIC LIST --- //
// ------------------ //

.topic {
  margin-top: 0;
  a {
    display: block;
    color: variables.$dark-grey;
    text-decoration: none;
    #{variables.$link-states} {
      h3 {
        text-decoration: underline;
      }
    }
  }
  h3 {
    font-family: variables.$extrabold;
    text-transform: uppercase;
  }
  strong {
    display: block;
    font-family: variables.$regular;
    background-color: variables.$grey;

    .remoteSessionLabel {
      display: inline-block;
      margin-left: 0.6em;
      padding: 0.1em 0.4em;
      font-size: 85%;
      color: black;
      background-color: variables.$light-grey;
      font-family: variables.$bold;
    }
  }
}

// --- Mobile --- //

// @include sass-mq.mq($until: tablet) {
.topic {
  li:nth-child(even) {
    border-top: 1px solid variables.$grey;
    border-bottom: 1px solid variables.$grey;
  }
  a {
    padding: variables.em(35, 12) variables.em(20, 12) variables.em(35, 12)
      variables.em(120, 12);
    min-height: variables.em(78, 12); // Height of background image
    // Background images
    &::before {
      left: 20px;
      top: 50%;
      margin-top: variables.em(-39, 12);
      width: variables.em(81, 12);
      height: variables.em(78, 12);
    }
  }
  strong {
    margin: variables.em(10, 12) 0;
    padding: variables.em(10, 12) variables.em(66, 12) variables.em(10, 12)
      variables.em(20, 12);
    // Calendar image
    &::after {
      right: 15px;
      margin-top: variables.em(-13, 12);
      width: variables.em(30, 12);
      height: variables.em(27, 12);
      background-position: -570px -450px;
    }
  }
}
