// --------------- //
// --- GENERIC --- //
// --------------- //

@use 'sass:math';

$link-states: '&:focus, &:hover, &:active';

// -------------- //
// --- COLORS --- //
// -------------- //

$white: #fff;
$dark-white: #fbfaf9;
// $light-grey: #fef7f7;
$light-grey: #e9e9e9;
// $grey: #bbb4a8;
// $grey: #eae4db;
$grey: #dcdcdc;
$dark-grey: #1a1a1a;

$dark-green: rgb(71, 105, 89);
$light-green: #519693;

$dark-blue: #00325e;
$blue: #157db9;
$light-blue: #6d68ff;
$flashy-blue: #2c6ef1;
$faded-blue: #f7f9fe;

$brand: $dark-blue; // #0e4f4c;
// ----------------- //
// --- FONT-FACE --- //
// ----------------- //

@font-face {
  font-family: 'BebasNeue-Regular';
  src:
    url('../fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('../fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist bold';
  src:
    url('../fonts/Urbanist-VariableFont_wght.woff2') format('woff2'),
    url('../fonts/Urbanist-VariableFont_wght.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  // font-stretch: PERCENTAGE_RANGE_LOW_TO_HIGH;
}

@font-face {
  font-family: 'Urbanist regular';
  src:
    url('../fonts/Urbanist-VariableFont_wght.woff2') format('woff2'),
    url('../fonts/Urbanist-VariableFont_wght.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  // font-stretch: PERCENTAGE_RANGE_LOW_TO_HIGH;
}

$regular: 'Urbanist regular', Arial, Helvetica, sans-serif;
$bold: 'Urbanist bold', Arial, Helvetica, sans-serif;
$extrabold: 'Urbanist bold', Arial, Helvetica, sans-serif;

// ----------------- //
// --- FUNCTIONS --- //
// ----------------- //

$base-font-size: 16;

@function lh($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return #{math.div($target, $context)};
}

@function em($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return #{math.div($target, $context)}em;
}

@function rem($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return #{math.div($target, $context)}rem;
}

// ------------------- //
// --- BREAKPOINTS --- //
// ------------------- //

$mq-media-type: screen;

$mq-breakpoints: (
  mobile: 320px,
  tablet: 768px,
  tablet_middle_boundary: 950px,
  tablet_upper_boundary: 1045px,
  desktop: 1240px,
  wide: 1420px,
);
