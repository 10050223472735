// Rules dedicated to print
#main .printOnly {
  display: none;
}

@media print {
  @page {
    margin: 40px;

    @bottom-center {
      content: 'Page ' counter(page);
    }
  }

  // Training page
  body.training {
    background-color: white;

    // Reset colors
    .details,
    #program,
    #nextSessions,
    #audience {
      background-color: white;
      color: black;
    }

    .inner-wrapper > div.tab {
      display: block;
    }

    // Hide useless elements
    #header,
    nav,
    .summary ul,
    #footer,
    #nextSessions,
    #testimony,
    #trainer,
    #logistic,
    #ratings,
    #contact {
      display: none;
    }

    // Reset rules
    h1,
    a {
      color: black;
      background-color: white;
    }
    .inner-wrapper .editorial {
      padding: 0;
    }

    header {
      border: none;
    }

    a {
      text-decoration: none;
    }

    // Then set print rules
    line-height: 1.5;

    // We cannot use `section` tag to with `break-inside: avoid-page` rule to
    // prevent page break between title and content because it could leave wide
    // blank spaces and waste paper.  Also (sadly) `break-after` is still not
    // working in main browsers 😤.
    h1,
    h2,
    h3 {
      margin-bottom: 10px;
      padding-bottom: 5px;
      break-after: avoid-page;
    }

    h1 {
      margin-top: 0;
    }

    h2 {
      font-size: 1.3rem;
    }

    h2,
    h3 {
      margin-top: 20px;
    }

    p {
      orphans: 2;
      widows: 2;
    }

    .teaser {
      display: block;
      background-color: white;
      margin: 10px 0;

      span {
        display: inline;

        // First letter is uppercased but we wan’t it lowercase.  We should’nt
        // apply that rule to whole block since there is some text that should
        // not be lowercased (ie. HT).  However `::first-letter` rule won’t work
        // on non-block display.  We wan still faint with `abbr` tags to force
        // uppercasing even if it’s not the best solution.
        text-transform: lowercase;

        abbr {
          text-transform: uppercase;
        }

        // Add missing spaces before and after price label.
        // Example: « 3 jours de formation à partir de 1 200 € ht par personne ».
        &:after,
        &:before {
          content: ' ';
        }
      }

      // Add missing dot at end of line.
      &:after {
        content: '.';
      }
    }

    .pricing-grid table {
      th,
      td {
        text-align: right;
        width: 50%;
      }
      .right.aligned {
        text-align: left;
      }
    }
  }
}
