@use 'utilities/variables';

@use 'sass-mq' with (
  $breakpoints: variables.$mq-breakpoints,
  $media-type: variables.$mq-media-type
);

// Imports
@use 'utilities/normalize';

// Gerenic
@use 'typo';
@use 'utilities/select';
@use 'utilities/popup.scss';

// Layout
@use 'layouts/aside';
@use 'layouts/header';
@use 'layouts/content';
@use 'layouts/footer';
@use 'layouts/grids';

// Pages
@use 'pages/about';
@use 'pages/article';
@use 'pages/articles';
@use 'pages/contact';
@use 'pages/home';
@use 'pages/info';

@use 'components/buttons';
@use 'components/cards';
@use 'components/forms';
@use 'components/intro';
@use 'components/outro';
@use 'components/pagination';
@use 'components/topics';

// Dedicated to training page print
@use 'print';

// Desktop
@include sass-mq.mq($from: desktop) {
  body {
    position: relative;
    background-color: variables.$white;

    main {
      padding-top: 7rem;
    }
  }

  .inner-nav,
  #main,
  .inner-wrapper,
  #customized,
  .article .cta,
  #footer {
    position: relative;
    z-index: 2; // Push elements forward body::before
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
  #nav .inner-nav,
  .inner-wrapper,
  #customized,
  .article .cta {
    width: variables.em(1200, 18);
  }
}
