@use '../utilities/variables';
@use 'sass-mq';

// ------------- //
// --- ASIDE --- //
// ------------- //

blockquote,
aside.tip,
aside.caution {
  padding: 24px 32px;
  border-left: 3px solid variables.$blue;
  border-right: 3px solid variables.$blue;
  max-width: 600px;
  border-radius: 5px;
  margin: 4rem auto;
  position: relative;
  color: variables.$dark-grey;
  background-color: variables.$light-grey;
  box-shadow:
    0.3px 0.3px 0px rgba(0, 0, 0, 0.035),
    2px 2px 0px rgba(0, 0, 0, 0.07);

  // &:before {
  //   content: '💡';
  //   display: block;
  //   position: absolute;
  //   background-color: variables.$light-grey;
  //   border: 3px solid variables.$blue;
  //   border-radius: 50%;
  //   height: 40px;
  //   width: 40px;
  //   line-height: 40px;
  //   left: -25px;
  //   top: 20px;
  //   text-align: center;
  // }

  h2,
  h3 {
    margin-top: 0;
  }

  strong {
    margin-bottom: 10px;
  }

  a {
    color: variables.$blue;

    &:hover,
    &:focus {
      color: variables.$dark-blue;
    }
  }

  .source {
    font-size: 0.9rem;
  }
}

aside.caution {
  border-left: 3px solid variables.$dark-green;

  &:before {
    content: '❗';
    background-color: variables.$light-grey;
    border: 3px solid variables.$dark-green;
  }
}

blockquote cite {
  font-style: italic;

  &::before {
    content: '— ';
  }
}

// Blockquote
// blockquote {
//   margin: 0;
//   p {
//     &::before,
//     &::after {
//       display: inline-block;
//     }
//     &:first-of-type::before {
//       content: '« ';
//     }
//     &:last-of-type::after {
//       content: ' »';
//     }
//   }
// }

// html[lang='en'] blockquote p {
//   &:first-of-type::before {
//     content: '“';
//   }
//   &:last-of-type::after {
//     content: '”';
//   }
// }
