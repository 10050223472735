@use '../utilities/variables';

.c-card {
  color: variables.$dark-grey;
  padding-bottom: 1rem; // Meta height + top and bottom padding
  border-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow:
    0px 0.3px 0.9px rgba(0, 0, 0, 0.028),
    0px 0.9px 2.9px rgba(0, 0, 0, 0.042),
    0px 4px 13px rgba(0, 0, 0, 0.07);
  overflow: hidden;

  #{variables.$link-states} {
    box-shadow:
      0px 0.3px 0px rgba(0, 0, 0, 0.028),
      0px 0.9px 0px rgba(0, 0, 0, 0.042),
      0px 4px 0px rgba(0, 0, 0, 0.07);
  }

  article {
    height: 100%;
  }

  svg,
  img {
    align-self: center;
    max-height: 250px;
  }

  .c-card__reading-time::before {
    content: '⏱';
    margin-right: 5px;
  }

  time::after {
    content: '';
  }

  span {
    text-align: center;
    padding: 0 1rem;
  }

  a {
    color: variables.$dark-grey;

    .c-card__media,
    .c-card__image,
    .c-card__content,
    svg,
    img {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .c-card__media,
    svg,
    img {
      transition: transform 250ms cubic-bezier(0.1, 0.2, 0.3, 0.4);
      opacity: 0.7;

      &:hover,
      &:focus {
        transform: scale(1.1);
        opacity: 1;
      }
    }

    svg {
      max-width: min(250px, calc(100% - 4rem));
    }

    img {
      max-width: min(300px, calc(100% - 4rem));
    }
  }
}

.c-card .c-card__with-meta {
  // Meta is using absolute positionning. We must therefore ensure it has enough space.
  padding-bottom: 2rem; // Meta height + top and bottom padding
  position: relative;

  .c-card__meta {
    color: variables.$grey;
    font-size: 10pt;
    height: 1rem;
    width: calc(100% - 2rem);
    padding: 10px 1rem;
    // background-color: $light-grey;
    // border-top: 1px solid $light-grey;

    position: absolute;
    bottom: 0;

    display: flex;
    justify-content: center;
  }
}

.c-card__title {
  font-size: 1.3rem;
  text-align: center;

  // background-color: $marigold;
  border-bottom: 1px solid variables.$grey;
  color: variables.$brand;
}

.c-card {
  a {
    text-decoration: none;
  }

  .c-card__link {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .c-card__centered-link {
    align-items: center;
  }

  .c-card__title,
  .c-card__content {
    padding: 1rem;
  }

  .c-card__title,
  .c-card__content {
    margin-bottom: 1rem;
  }

  .c-card__content {
    color: variables.$dark-grey;
    font-size: 11pt;
    padding: 0 1rem;
  }

  .c-card__tags {
    margin: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;

    img {
      height: 40px;
      margin: 0 2px;
    }

    // Text tag
    span {
      padding: 3px 5px;
      text-decoration: none;
      border-radius: 5px;
      background-color: variables.$grey;
      color: variables.$white;
      font-size: 10pt;
    }
  }

  .c-card__image {
    .c-card__media,
    svg {
      width: 100%;
      // border-bottom: 1px solid #eee;
    }

    .c-card__title {
      background-color: variables.$white;
      border-bottom: 1px solid variables.$light-grey;
      color: variables.$dark-grey;
      padding-top: 0;
    }
  }

  &.c-card__no-image .c-card__title {
    font-size: 1.3rem;
  }

  .c-card__tag {
    height: 20px;
  }
}

main.articles {
  .c-card__link {
    height: 100%;
    place-content: center;

    h2 {
      border-bottom: none;
    }
  }
}
