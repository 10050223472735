@use '../utilities/variables';
@use 'sass-mq';

form {
  padding: 2rem;
  margin: 2rem 0;
  background-color: variables.$faded-blue;

  label {
    display: block;

    &:has(~ *[required]):after {
      content: '*';
      font-size: 80%;
      color: variables.$blue;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    width: 100%;
    padding: 0.2rem 0.2rem;
    border-radius: 5px;
    border: 1px solid variables.$grey;
  }

  label[hidden] {
    display: none;
  }
}

@include sass-mq.mq($from: desktop) {
  form {
    .cta {
      // Decorative borders
      &::before,
      &::after {
        width: calc((100% - 300px - 60px) / 2);
      }
      a,
      button {
        width: variables.em(300, 18);
      }
    }
  }
}
