@use '../utilities/variables';
@use 'sass-mq';

// ---------------- //
// --- HOMEPAGE --- //
// ---------------- //

.home main {
  background-color: transparent;

  .services {
    background-image: linear-gradient(transparent, #6d68ff5f, transparent);
  }

  h2 {
    font-size: 3rem;
    margin-top: 7rem;
    padding-top: 0;
  }

  .c-card {
    background-color: variables.$white;
  }

  .contact-cta {
    display: flex;
    flex-direction: column;
    place-items: center;
    margin-top: 7rem;
    gap: 2rem;

    a,
    svg {
      max-width: 350px;
    }

    a {
      font-size: 1.2rem;
    }

    svg {
      max-height: 300px;
    }
  }

  p,
  ul,
  li,
  a {
    font-size: 1.2rem;
  }

  .intro > div {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 2rem;
    max-width: 72rem;
    text-align: left;
  }

  #services article {
    display: flex;
    gap: 2rem;
    max-width: 72rem;
    margin: 4rem auto;
    padding: 3rem;
    place-items: center;

    h3 {
      font-size: 1.9rem;
    }

    svg {
      width: 100%;
      max-height: 300px;
    }

    &:nth-of-type(odd) {
      background-color: variables.$faded-blue;
    }
  }

  @include sass-mq.mq($until: tablet) {
    .intro > div {
      flex-direction: column;
    }
    section:not(#services),
    .contact-cta {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    #services article {
      padding-left: 1rem;
      padding-right: 1rem;
      flex-direction: column;
    }
    .contact-cta {
      margin-top: 3rem;

      svg {
        display: none;
        margin-top: 3rem;
      }
    }
  }
}

@include sass-mq.mq($from: desktop) {
  .home main {
    margin-bottom: 10rem;
  }
}
