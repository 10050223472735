@use '../utilities/variables';
@use 'sass-mq';

// ------------- //
// --- ABOUT --- //
// ------------- //

.ecoindex {
  margin: 2rem 0;
  padding: 2rem;
  background-color: variables.$light-grey;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  #ecoindex-badge {
    margin-top: 2rem;
  }
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  .staff-wrapper {
    padding-right: variables.em(20, 12);
    padding-left: variables.em(20, 12);
    img {
      border-bottom-width: variables.em(6, 12);
    }
    h3 {
      margin: variables.em(20, 15) 0;
    }
    .maxime {
      margin-top: variables.em(40, 12);
    }
  }
}

// --- Tablet & Desktop --- //

@include sass-mq.mq($from: tablet) {
  // Main title
  .about section > h1::before {
    top: 0;
    right: 0;
  }
  .staff-wrapper {
    display: table;
    width: 100%;
    > div {
      display: table-cell;
      box-sizing: border-box;
      width: 50%;
    }
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  // Main title
  .about section > h1::before {
    width: variables.em(214, 30);
    height: variables.em(77, 30);
    background-position: variables.em(-619, 30) variables.em(-778, 30);
  }
  .staff-wrapper {
    margin-top: variables.em(-28, 14);
    > div {
      padding-right: variables.em(20, 14);
      padding-left: variables.em(20, 14);
    }
    img {
      border-bottom-width: variables.em(7, 14);
    }
    h3 {
      margin: variables.em(24, 17) 0;
    }
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  // Main title
  .about section > h1::before {
    width: variables.em(280, 40);
    height: variables.em(102, 40);
    background-position: variables.em(-616, 40) variables.em(-674, 40);
  }
  .staff-wrapper {
    margin-top: variables.em(-31, 18);
    > div {
      padding-right: variables.em(30, 18);
      padding-left: variables.em(30, 18);
    }
    img {
      border-bottom-width: variables.em(10, 18);
    }
    h3 {
      margin: variables.em(28, 22) 0;
    }
  }
}
