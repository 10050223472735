@use 'variables';
@use 'sass-mq';

// ------------- //
// --- POPUP --- //
// ------------- //

// overlay
$mfp-overlay-color: #0b0b0b !default; // Color of overlay screen
$mfp-overlay-opacity: 0.8 !default; // Opacity of overlay screen
$mfp-shadow: 0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left: 8px !default; // Padding from left and from right side
$mfp-popup-padding-left-mobile: 6px !default; // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base: 1040 !default; // Base z-index of popup

// controls
$mfp-include-arrows: true !default; // Include styles for nav arrows
$mfp-controls-opacity: 0.65 !default; // Opacity of controls
$mfp-controls-color: #fff !default; // Color of controls
$mfp-controls-border-color: #3f3f3f !default; // Border color of controls
$mfp-inner-close-icon-color: #333 !default; // Color of close button when inside
$mfp-controls-text-color: #ccc !default; // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover: #fff !default; // Hover color of preloader and "1 of X" indicator

// Image-type options
$mfp-include-image-type: true !default; // Enable Image-type popups
$mfp-image-background: #444 !default;
$mfp-image-padding-top: 40px !default; // Image padding top
$mfp-image-padding-bottom: 40px !default; // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color: #f3f3f3 !default; // Caption title color
$mfp-caption-subtitle-color: #bdbdbd !default; // Caption subtitle color

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////

////////////////////////
// 1. General styles
////////////////////////

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $mfp-overlay-color;
  opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
  box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $mfp-z-index-base + 5;
}
.mfp-inline-holder,
.mfp-ajax-holder {
  .mfp-content {
    width: 100%;
    cursor: auto;
  }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

// Hide the image during the loading
.mfp-loading {
  &.mfp-figure {
    display: none;
  }
}

.mfp-hide {
  display: none !important;
}

////////////////////////
// 2. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
  color: $mfp-controls-text-color;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $mfp-z-index-base + 4;
  a {
    color: $mfp-controls-text-color;
    &:hover {
      color: $mfp-controls-text-color-hover;
    }
  }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
  .mfp-preloader {
    display: none;
  }
}

// Hide content when it was not loaded
.mfp-s-error {
  .mfp-content {
    display: none;
  }
}

// CSS-reset for buttons
button {
  &.mfp-close,
  &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: $mfp-z-index-base + 6;
    box-shadow: none;
    touch-action: manipulation;
  }
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

// Image content type
@if $mfp-include-image-type {
  /* Main image in popup */
  img {
    &.mfp-img {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: $mfp-image-padding-top;
      bottom: $mfp-image-padding-bottom;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
      box-shadow: $mfp-shadow;
      background: $mfp-image-background;
    }
    small {
      color: $mfp-caption-subtitle-color;
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    figure {
      margin: 0;
    }
  }
  .mfp-image-holder {
    .mfp-content {
      max-width: 100%;
      width: auto !important;
    }
  }
  .mfp-gallery {
    .mfp-image-holder {
      .mfp-figure {
        cursor: pointer;
      }
    }
  }
}

// Arrow navigation
.mfp-arrow {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &,
  img {
    position: absolute;
    top: 50%;
  }
  img {
    opacity: 0.75;
  }
  #{variables.$link-states} {
    img {
      opacity: 1;
    }
  }
}
button.mfp-arrow {
  z-index: $mfp-z-index-base + 6;
  background-color: variables.$brand;
}
.mfp-arrow-left img {
  right: 50%;
}
.mfp-arrow-right img {
  left: 50%;
}

// Close icon
.mfp-close {
  position: absolute;
  padding: 0 0 2px 2px;
  font-family: variables.$bold;
  color: rgba(variables.$white, 0.75);
  text-align: center;
  cursor: pointer;
  #{variables.$link-states} {
    color: variables.$white;
  }
}
button.mfp-close {
  background-color: variables.$brand;
}

// Image
.mfp-figure {
  position: relative;
  z-index: $mfp-z-index-base + 7;
}
.mfp-img {
  border: 5px solid variables.$blue;
}

// Caption
.mfp-bottom-bar {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}
.mfp-title {
  word-wrap: break-word;
  background-color: variables.$white;
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  // Container
  .mfp-container {
    padding-right: variables.em(40, 12);
    padding-left: variables.em(40, 12);
  }
  // Close button
  button.mfp-close {
    right: -15px;
    top: -15px;
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    border-radius: 15px;
  }
  // Arrow navigation
  .mfp-arrow {
    margin-top: -20px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    img {
      width: 10px;
      height: auto;
      margin-top: -8px;
    }
  }
  .mfp-arrow-left {
    left: -23px;
    img {
      margin-right: 3px;
    }
  }
  .mfp-arrow-right {
    right: -23px;
    img {
      margin-left: 3px;
    }
  }
  // Image
  .mfp-img {
    border-width: 3px;
  }
  // Title
  .mfp-title {
    padding: variables.em(12, 12) variables.em(20, 12);
    line-height: variables.lh(20, 12);
  }
  // Inline content
  .mfp-inline-holder {
    padding-right: 0;
    padding-left: 0;
    .mfp-close {
      top: 4px;
      right: 10px;
    }
  }
}

// --- Tablet & Desktop --- //

@include sass-mq.mq($from: tablet) {
  // Close button
  button.mfp-close {
    right: -20px;
    top: -20px;
    padding-bottom: 3px;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 40px;
    border-radius: 20px;
    line-height: 20px;
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  // Container
  .mfp-container {
    padding-right: variables.em(60, 14);
    padding-left: variables.em(60, 14);
  }
  // Arrow navigation
  .mfp-arrow {
    margin-top: -30px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    img {
      width: 15px;
      height: auto;
      margin-top: -12px;
    }
  }
  .mfp-arrow-left {
    left: -35px;
    img {
      margin-right: 3px;
    }
  }
  .mfp-arrow-right {
    right: -35px;
    img {
      margin-left: 3px;
    }
  }
  // Title
  .mfp-title {
    padding: variables.em(14, 14) variables.em(20, 14);
    line-height: variables.lh(22, 14);
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  // Gallery
  // Arrow navigation
  .mfp-arrow {
    margin-top: -40px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    img {
      margin-top: -16px;
    }
  }
  .mfp-arrow-left {
    left: -45px;
    img {
      margin-right: 5px;
    }
  }
  .mfp-arrow-right {
    right: -45px;
    img {
      margin-left: 5px;
    }
  }
  // Title
  .mfp-title {
    padding: variables.em(14, 14) variables.em(30, 14);
    font-size: variables.em(14, 18);
    line-height: variables.lh(22, 14);
  }
}
