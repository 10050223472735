@use '../utilities/variables';
@use 'sass-mq';

.outro {
  text-align: center;
  background-color: variables.$faded-blue;
}

@include sass-mq.mq($until: tablet) {
  .outro {
    padding: 2rem;
    margin: 2rem auto 0;
    font-size: 1rem;
  }
}

@include sass-mq.mq($from: tablet) {
  .outro {
    margin: 5rem auto 0;
    max-width: 800px;
    padding: 3rem;
  }
}
