@use 'sass:color';
@use 'variables';
@use 'sass-mq';

// Style the dropdown
.nice-select {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: solid 1px variables.$grey;
  width: auto;
  outline: none;
  font-family: variables.$bold;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  background-color: variables.$white;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  #{variables.$link-states} {
    border-color: color.adjust(variables.$grey, $lightness: -15%);
    &::after {
      border-right-color: color.adjust(variables.$grey, $lightness: -15%);
      border-bottom-color: color.adjust(variables.$grey, $lightness: -15%);
    }
  }
  // Arrow
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    border-width: 0;
    border-style: solid;
    border-color: variables.$grey;
    pointer-events: none;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
  }
  &.open {
    &::after {
      transform: rotate(-135deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  // List and options
  .list {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 9;
    top: 100%;
    left: 0;
    box-sizing: border-box;
    margin-top: 1px;
    padding: 0;
    pointer-events: none;
    background-color: variables.$white;
    box-shadow: 0 0 0 1px rgba(variables.$dark-grey, 0.11);
    transform-origin: 50% 0;
    transition:
      all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
      opacity 0.15s ease-out;
    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
  }
  .option {
    outline: none;
    list-style: none;
    cursor: pointer;
    text-align: left;
    transition: all 0.2s;
    &:hover,
    &.focus,
    &.selected.focus {
      background-color: variables.$light-grey;
    }
  }
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  .nice-select {
    padding-right: 30px;
    padding-left: 5px;
    height: variables.em(30, 12);
    line-height: variables.lh(30, 12);
    .list {
      line-height: inherit;
      transform: scale(0.75) translateY(-15);
    }
    // List & options
    .option {
      padding-left: 5px;
      padding-right: 30px;
      min-height: variables.em(28, 12);
      line-height: inherit;
    }
    // Arrow
    &::after {
      border-bottom-width: 4px;
      border-right-width: 4px;
      height: 8px;
      width: 8px;
      margin-top: -8px;
    }
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  .nice-select {
    padding-right: 40px;
    padding-left: 20px;
    height: variables.em(36, 14);
    line-height: variables.lh(36, 14);
    // List & options
    .list {
      line-height: inherit;
      transform: scale(0.75) translateY(-18);
    }
    .option {
      padding-left: 20px;
      padding-right: 40px;
      min-height: variables.em(36, 14);
      line-height: inherit;
    }
    // Arrow
    &::after {
      border-bottom-width: 5px;
      border-right-width: 5px;
      height: 10px;
      width: 10px;
      margin-top: -10px;
    }
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  .nice-select {
    padding-right: 40px;
    padding-left: 20px;
    height: variables.em(42, 18);
    line-height: variables.lh(42, 18);
    // List & options
    .list {
      line-height: inherit;
      transform: scale(0.75) translateY(-21);
    }
    .option {
      padding-left: 20px;
      padding-right: 40px;
      min-height: variables.em(42, 18);
      line-height: inherit;
    }
    // Arrow
    &::after {
      border-bottom-width: 6px;
      border-right-width: 6px;
      height: 12px;
      width: 12px;
      margin-top: -12px;
    }
  }
}
