@use '../utilities/variables';
@use 'sass-mq';

// -------------- //
// --- FOOTER --- //
// -------------- //

#footer {
  font-size: 0.9rem;
  margin-top: 5rem;
  color: variables.$white;
  background-color: variables.$light-grey;

  .inner-wrapper {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: variables.$light-grey;
    color: variables.$dark-grey;
  }

  ul,
  > p {
    margin: 0;
  }

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.5rem;

    list-style: none;
    padding: 0;
    font-size: 10pt;

    li {
      margin: 5px;
    }
  }

  // Copyright
  // small {
  //   width: 100%;
  //   padding: 1rem;
  //   text-align: center;
  //   font-size: 10pt;
  // }

  .c-footer--infos {
    width: 100%;
  }

  .c-footer--links {
    flex-wrap: wrap;

    li > a {
      color: variables.$dark-grey;
      text-decoration: none;

      &:focus,
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  .c-footer--socialmedia {
    justify-content: center;
    gap: 1rem;
    li {
      flex: 0;
    }
  }

  .c-icon {
    fill: variables.$dark-grey;
  }
}

a.c-footer--newsletter-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 13rem;

  margin-bottom: 1rem;
  padding: 0.5rem;

  // background: white;
  border-radius: 4px;
  text-align: center;

  text-decoration: none;
  // color: #1eb9d7;
  font-size: 0.8rem;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    // background: $light-grey;
  }

  img {
    max-height: 3rem;
  }

  strong {
    margin: 0.5rem 0 0;
    font-size: 1rem;
  }
}

.qualiopi {
  font-size: 90%;
  font-family: variables.$regular;
  text-align: center;

  img {
    height: 5rem;
    display: block;
    margin: 0 auto 1rem;
  }
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  #footer {
    margin-top: 5rem;

    .c-footer--infos {
      display: block;
      text-align: center;
    }
    ul {
      width: 100%;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid variables.$light-grey;
      text-align: center;
    }
  }
}
