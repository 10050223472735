@use '../utilities/variables';
@use 'sass-mq';

.info {
  h1.with-date {
    line-height: 2;
  }

  h2:first-child {
    padding-top: 0;
  }

  .heroUnit {
    text-align: center;
    margin-block: 2rem;

    img {
      max-height: 250px;
    }
  }

  .cta.audit a,
  .cta.dev a {
    background-color: variables.$light-blue;
  }
  .cta.stack a {
    background-color: variables.$blue;
  }
  .cta.poc a {
    background-color: variables.$dark-blue;
  }

  .locales {
    padding: 0.5rem 1rem;
    background-color: variables.$light-grey;
  }

  img {
    max-width: 100%;
  }

  .update {
    padding: 0.5rem 1rem;
    background-color: variables.$light-grey;
    font-style: italic;
    border: 5px solid variables.$blue;
  }

  p.highlight {
    font-style: italic;
    font-size: 1.2em;
    color: #1eb9d7;
    padding-left: 35px;
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  .info h2 {
    margin-right: 0;
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  .info {
    .inner-wrapper {
      // Sections
      section {
        padding: 0 variables.em(30, 18) variables.em(56, 18)
          variables.em(430, 18);

        &.no-internal-nav {
          padding: variables.em(30, 18) variables.em(180, 18)
            variables.em(56, 18) variables.em(180, 18);

          img {
            max-width: 70%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    h2 {
      margin: 0 0 variables.em(14, 26);
      padding-top: variables.em(32, 26);
      text-align: left;
    }
    h3 {
      margin: 0 0 variables.em(14, 26) 0;
      padding-top: variables.em(32, 26);
      text-align: left;
    }
    ul {
      padding-left: variables.em(18, 18);
    }
    // Code
    code,
    tt {
      line-height: variables.lh(30, 18);
    }
  }
}

// Internal nav
#internal {
  position: absolute;
  z-index: 1;
  font-size: 0.9rem;

  ul {
    list-style: none;
    padding-left: 0;
  }
  a {
    display: block;
    padding: 3px 10px;
    text-decoration: none;
    // transition:
    //   background-color 0.75s ease,
    //   color 0.75s ease;
    &:hover,
    &:focus {
      color: variables.$white;
      background: variables.$brand;
    }
  }
  // JS behavior
  &.is-sticky {
    position: fixed;
    top: 0;
    z-index: 3;
  }
  // Active link
  li.is-active {
    a {
      position: relative;
      font-family: variables.$bold;
      // color: variables.$white;
      // background: variables.$blue;
      &::after {
        content: '\000BB';
        display: inline-block;
        margin-left: 10px;
        font-family: inherit;
        font-weight: normal;
      }
      code,
      tt {
        color: variables.$white;
      }
    }
  }
}
