@use '../utilities/variables';
@use 'sass-mq';

@include sass-mq.mq($until: tablet) {
  main.articles {
    padding-top: 4.5rem;
  }

  main.articles .intro {
    margin: 0 auto 2rem;
    padding-top: 0;
  }
}

@include sass-mq.mq($from: tablet) {
  main.articles {
    .intro {
      max-width: 800px;
      margin: 0 auto 3rem;
    }
  }
}

// Code block customization over Prism Okaida theme (loaded remotely from <head>)

code[class*='language-'],
pre[class*='language-'] {
  font-family: 'Operator Mono SSm A', 'Operator Mono SSm B',
    'Operator Mono SSm', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
  // Some of the legacy contents was set for different base font size and created hscrolls.
  font-size: 0.97em;
  max-height: 600px;
}
