@use '../utilities/variables';
@use 'sass-mq';

// --------------- //
// --- CONTACT --- //
// --------------- //

body.contact {
  .intro {
    text-align: center;
    margin-bottom: 0;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  ul {
    text-align: left;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    grid-template-areas:
      'name company'
      'email phone'
      'message message'
      'action action';
  }

  .email {
    grid-area: email;
  }
  .phone {
    grid-area: phone;
  }
  .name {
    grid-area: name;
  }
  .company {
    grid-area: company;
  }

  .message {
    grid-area: message;
  }

  .action {
    grid-area: action;

    button {
      margin: 0;
    }
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  // Main title
  .contact section > h1::before {
    width: variables.em(295, 40);
    background-position: 0 -21em;
  }
}

@include sass-mq.mq($from: tablet) {
  // Main title
  .contact section > h1::before {
    top: 0;
    right: 20px;
    bottom: 0;
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  body.contact {
    // Main title
    .contact section > h1::before {
      width: variables.em(220, 30);
      background-position: variables.em(-845, 30) variables.em(-778, 30);
    }
  }
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  body.contact {
    .l-grid {
      padding: 0;
    }

    .address-wrapper address strong,
    .address-wrapper address p,
    .address-wrapper p,
    .contact-wrapper > a {
      height: 100px;
    }
  }
}

.success {
  text-align: center;
}
