@use '../utilities/variables';
@use 'sass-mq';

.intro {
  h1 {
    margin-top: 0;
    margin-bottom: 3rem;
    font-size: 5rem;
    color: variables.$dark-blue;
    font-family: 'Urbanist bold', Arial;
    text-align: left;
    line-height: 1.2;

    span {
      position: relative;
      color: rgba(0, 0, 0, 0); //variables.$blue;
      background-image: linear-gradient(
        to right,
        rgb(21, 101, 192),
        rgb(149, 127, 239)
      );
      background-clip: text;

      svg {
        fill: rgb(149, 127, 239);
        // opacity: 0.5;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.25rem;
      }
    }
  }

  p {
    font-size: 1.2rem;
  }

  svg {
    max-height: 340px;
  }

  @include sass-mq.mq($until: tablet) {
    padding-top: 4.5rem;

    h1 {
      font-size: 3rem;
      margin-bottom: 0;
    }

    svg {
      max-width: min(300px, calc(100% - 2rem));
      max-height: 250px;
    }
  }
}

// @include sass-mq.mq($until: tablet) {
//   .intro {
//     margin: 0 1rem;
//     padding-bottom: variables.em(56, 18);
//     flex-wrap: wrap;
//   }
// }

// @include sass-mq.mq($from: tablet) {
//   .intro {
//     padding: 0 variables.em(180, 18) 0 variables.em(180, 18);
//     margin-bottom: 0;
//   }
// }
