@use '../utilities/variables';
@use 'sass-mq';

// -------------------- //
// --- MAIN CONTENT --- //
// -------------------- //
* {
  scroll-behavior: smooth;
}

@include sass-mq.mq($from: tablet) {
  .home main {
    padding-top: 12rem;
  }

  main.article {
    padding-top: 10rem;
  }
}

@include sass-mq.mq($until: tablet) {
  main.article {
    padding-top: 6rem;
  }
}

// Inner-wrapper
.inner-wrapper {
  // background-color: variables.$white;
  padding: 1rem 5px 1rem;
}

.inner-wrapper__centered {
  display: flex;
  place-content: center;
}

#intro {
  margin: 0 2rem 2rem;
  max-width: 750px;
  text-align: center;
}

body {
  font-size: 1.2rem;
}

// Introduction with columns
.introduction {
  border-bottom-color: variables.$grey;
  border-bottom-style: solid;
}

// h1.with-date {
//   line-height: 2.5;
// }

h1 .published-at {
  padding-top: 0.5rem;
  margin: 0.5rem 30% 0.5rem;
  text-align: center;
  text-decoration: italic;
  text-transform: none;
  font-size: 8pt;
  font-weight: normal;
  border-top: 1px solid #eee;

  span {
    color: variables.$dark-grey;
  }
}

// See links
.see {
  font-family: variables.$bold;
  &::before {
    content: '\000BB';
    display: inline-block;
    margin-right: 5px;
    font-family: inherit;
    font-weight: normal;
  }
  + .see {
    margin-left: 15px;
  }
}

// Supports & Articles
.presentation {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  li:first-child {
    margin-top: 0;
  }
  // Image link
  a {
    #{variables.$link-states} {
      img {
        opacity: 0.75;
        border-bottom-color: variables.$grey;
      }
    }
  }
  img {
    vertical-align: top;
    border-bottom-style: solid;
    border-bottom-color: variables.$brand;
    transition:
      opacity 0.5s ease,
      border-bottom-color 0.5s ease;
  }
  .title {
    text-decoration: none;
    #{variables.$link-states} {
      text-decoration: underline;
    }
  }
  // Hidden content
  .hidden {
    // http://a11yproject.com/posts/how-to-hide-content/
    // https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    // position: absolute;
    // top: -100px;
    // left: -200px;
  }
}

// http://a11yproject.com/posts/how-to-hide-content/
// https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html
body:hover .visually-hidden {
  a,
  input,
  button {
    display: none !important;
  }
}

// Ribbon
.ribbon {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  font-family: variables.$extrabold;
  text-align: center;
  text-transform: uppercase;
  color: variables.$white;
  transform: rotate(-90deg);
  span {
    position: absolute;
    background-color: variables.$blue;
    transform: rotate(45deg);
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 100%;
      z-index: -1;
      border-style: solid;
      border-bottom-color: transparent;
      border-top-color: variables.$dark-blue;
    }
    &::before {
      left: 0;
      border-right-color: transparent;
      border-left-color: variables.$dark-blue;
    }
    &::after {
      right: 0;
      border-left-color: transparent;
      border-right-color: variables.$dark-blue;
    }
  }
}

// Topics list
.topic {
  padding-left: 0;
  list-style: none;
  li {
    &:nth-child(even) {
      background-color: variables.$dark-white;
    }
  }
}

// Testimony wrapper
#testimony {
  h2 {
    color: variables.$dark-grey;
    background-color: transparent;
  }
  ul {
    padding-left: 0;
    list-style: none;
  }
}

// Image with border
.has-border {
  box-sizing: border-box;
  border: 5px solid variables.$blue;
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  // Introduction
  .introduction {
    border-bottom-width: variables.em(6, 12);
    padding: 0 variables.em(20, 12) variables.em(44, 12);
  }
  :not(.shareon) {
    // Twitter block
    & > .twitter,
    & > .youtube {
      margin-top: variables.em(20, 12);
      a {
        padding: variables.em(15, 12) 0;
      }
      // Account name
      span {
        font-size: variables.em(17, 12);
      }
    }
  }
  // Supports & Articles
  .presentation {
    margin: 0 variables.em(20, 12) variables.em(20, 12);
    li {
      margin: variables.em(40, 12) 0;
    }
    // Image link
    img {
      border-bottom-width: 5px;
      height: auto;
      width: 100%;
      max-width: 100%;
    }
    h3 {
      margin: variables.em(10, 15) 0 variables.em(5, 15);
    }
    time,
    .event,
    h3 > span {
      font-size: variables.em(13, 15);
    }
    p {
      margin-bottom: variables.em(5, 12);
    }
  }
  // Ribbon
  .ribbon {
    top: -4px;
    left: -4px;
    width: 104px;
    height: 104px;
    font-size: variables.rem(13);
    line-height: variables.lh(26, 13);
    span {
      top: 29px;
      right: -33px;
      width: 150px;
      &::before,
      &::after {
        border-width: 4px;
      }
    }
  }
  // Testimony wrapper
  #testimony {
    position: relative;
  }
  // Testimony slider
  .m-scooch {
    overflow: hidden;
    margin-top: variables.em(40, 12);
    -webkit-font-smoothing: antialiased;
    // JS buttons
    button {
      position: absolute;
      top: variables.em(32, 12);
      border: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      background-color: transparent;
      path {
        transition: fill 0.5s ease;
      }
      #{variables.$link-states} {
        cursor: pointer;
        .circle {
          fill: variables.$white;
        }
        .symbol {
          fill: variables.$grey;
        }
      }
      &.m-inactive {
        #{variables.$link-states} {
          cursor: default;
          .circle {
            fill: variables.$grey;
          }
          .symbol {
            fill: variables.$white;
          }
        }
      }
    }
  }
  .m-scooch-inner {
    white-space: nowrap;
    // Animations
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.33, 0.66, 0.66, 1);
    transition-duration: 0.5s;
  }
  .m-scooch-item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    white-space: normal;
  }
  // JS buttons
  #previous {
    left: variables.em(20, 12);
  }
  #next {
    right: variables.em(20, 12);
  }
  .m-inactive {
    opacity: 0.25;
  }
  // Testimony list
  .testimony {
    img {
      margin-right: variables.em(12, 12);
    }
    p {
      margin: variables.em(10, 12) 0 variables.em(20, 12);
    }
  }
}

// --- Tablet & Desktop --- //

@include sass-mq.mq($from: tablet) {
  // Introduction with columns
  .introduction {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > p {
      box-sizing: border-box;
    }
  }
  :not(.shareon) {
    // Twitter block
    & > .twitter,
    & > .youtube {
      vertical-align: middle;
    }
  }
  // Supports & Articles
  .presentation {
    li {
      display: table;
      box-sizing: border-box;
    }
    > a,
    .content-wrapper {
      display: table-cell;
    }
    .content-wrapper {
      vertical-align: middle;
    }
  }
  // Supports & Articles (half view)
  .half {
    li {
      float: left;
      &:nth-child(2n + 1) {
        clear: left;
      }
    }
  }
  // Supports & Articles (tiny display)
  .tiny {
    h3 {
      font-size: inherit;
    }
    // Image link
    img {
      border-bottom-width: 2px;
    }
    .content-wrapper {
      vertical-align: top;
      width: 100%;
    }
  }
  // Testimony list
  // TODO UPDATE
  .home,
  .training {
    .testimony {
      display: flex;
      flex-wrap: wrap;
      transform: none !important;
      li {
        box-sizing: border-box;
        width: 50%;
      }
    }
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  // Introduction with columns
  .introduction {
    border-bottom-width: variables.em(7, 14);
    padding-bottom: variables.em(29, 14);
    flex-wrap: wrap;
    > p {
      padding: 0 variables.em(20, 14);
      width: 50%;
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 2;
      }
    }
  }
  :not(.shareon) {
    // Twitter block
    & > .twitter,
    & > .youtube {
      padding: 0 variables.em(14, 14) 0 variables.em(98, 14);
      width: variables.em(203, 14);
      // Account name
      span {
        font-size: variables.em(20, 14);
      }
    }
  }
  // Supports & Articles
  .presentation {
    margin-right: variables.em(20, 14);
    margin-left: variables.em(20, 14);
    li {
      margin: variables.em(24, 14) 0;
      &:last-child {
        margin-bottom: variables.em(36, 14);
      }
    }
    // Image link
    img {
      border-bottom-width: 6px;
      height: variables.em(198, 14);
      width: auto;
    }
    .content-wrapper {
      padding-left: variables.em(20, 14);
    }
    h3 {
      margin-bottom: variables.em(12, 17);
    }
    time,
    .event,
    h3 > span {
      font-size: variables.em(14, 17);
    }
    p,
    .see {
      font-size: variables.em(12, 14);
      line-height: variables.lh(19, 12);
    }
    p {
      margin-bottom: variables.em(6, 12);
    }
  }
  // Supports & Articles (half view)
  .half {
    li {
      margin-top: 0;
      margin-bottom: variables.em(36, 14);
      width: calc(50% - #{variables.em(20, 14)});
      &:nth-child(2n) {
        margin-left: variables.em(20, 14);
      }
      &:nth-child(2n + 1) {
        margin-right: variables.em(20, 14);
      }
    }
  }
  // Supports & Articles (tiny display)
  .tiny {
    // Image link
    img {
      height: variables.em(98, 14);
    }
    h3 {
      margin-bottom: variables.em(6, 14);
      font-size: variables.rem(14);
      line-height: variables.lh(21, 14);
    }
    time,
    .event,
    h3 > span {
      font-size: variables.em(13, 14);
    }
    p {
      margin-bottom: variables.em(6, 12);
    }
  }
  // Ribbon
  .ribbon {
    // Default size
    top: -4px;
    left: -4px;
    width: 104px;
    height: 104px;
    font-size: variables.rem(14);
    line-height: variables.lh(28, 14);
    span {
      top: 29px;
      right: -33px;
      width: 150px;
      &::before,
      &::after {
        border-width: 4px;
      }
    }
    // In tiny lists
    .tiny & {
      font-size: variables.rem(13);
      line-height: variables.lh(26, 13);
    }
  }
  // Testimony list
  // TODO UPDATE
  .home,
  .training {
    .testimony {
      li {
        padding: variables.em(18, 14) variables.em(20, 14);
      }
      img {
        width: 90px;
        height: auto;
      }
    }
  }
  // Testimony list
  .testimony {
    img {
      margin-right: variables.em(14, 14);
    }
    p {
      margin: variables.em(12, 14) 0 variables.em(24, 14);
    }
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  // Introduction with columns
  .introduction {
    border-bottom-width: variables.em(10, 18);
    padding-bottom: variables.em(31, 18);
    > p {
      padding: 0 variables.em(30, 18);
      width: 33.33333%;
    }
  }
  :not(.shareon) {
    // Twitter block
    & > .twitter,
    & > .youtube {
      padding: 0 variables.em(25, 18) 0 variables.em(111, 18);
      width: variables.em(234, 18);
      // Account name
      span {
        font-size: variables.em(20, 18);
      }
    }
  }
  // Supports & Articles
  .presentation {
    margin-right: variables.em(30, 18);
    margin-left: variables.em(30, 18);
    li {
      margin: variables.em(28, 18) 0;
      &:last-child {
        margin-bottom: variables.em(56, 18);
      }
    }
    // Image link
    img {
      border-bottom-width: 8px;
    }
    .content-wrapper {
      padding-left: variables.em(30, 18);
    }
    h3 {
      margin-bottom: variables.em(14, 22);
    }
    time,
    .event,
    h3 > span {
      font-size: variables.em(16, 22);
    }
    p {
      margin-bottom: 0;
      font-size: variables.em(14, 18);
      line-height: variables.lh(22, 14);
    }
  }
  // Supports & Articles (half view)
  .half {
    li {
      margin-top: 0;
      margin-bottom: variables.em(42, 18);
      width: calc(50% - #{variables.em(30, 18)});
      &:nth-child(2n) {
        margin-left: variables.em(30, 18);
      }
      &:nth-child(2n + 1) {
        margin-right: variables.em(30, 18);
      }
    }
  }
  // Supports & Articles (tiny display)
  .tiny {
    h3 {
      margin-bottom: variables.em(7, 18);
      line-height: variables.lh(24, 18);
    }
    time,
    .event,
    h3 > span {
      font-size: variables.em(16, 18);
    }
    p,
    .see {
      line-height: variables.lh(22, 14);
      font-size: variables.em(14, 18);
    }
  }
  // Ribbon
  .ribbon {
    // Default size
    top: -7px;
    left: -8px;
    width: 120px;
    height: 120px;
    font-size: variables.rem(16);
    line-height: variables.lh(30, 16);
    span {
      top: 35px;
      right: -35px;
      width: 169px;
      &::before,
      &::after {
        border-width: 5px;
      }
    }
    // In tiny lists
    .tiny & {
      top: -4px;
      left: -5px;
      width: 104px;
      height: 104px;
      font-size: variables.rem(14);
      line-height: variables.lh(28, 14);
      span {
        top: 29px;
        right: -33px;
        width: 150px;
        &::before,
        &::after {
          border-width: 4px;
        }
      }
    }
  }
  // Topics list
  .topic {
    margin-top: variables.em(15, 18);
  }
  // Testimony list
  .home,
  .training {
    .testimony {
      li {
        padding: variables.em(21, 18) variables.em(30, 18);
      }
    }
  }
  // Testimony list
  .testimony {
    img {
      margin-right: variables.em(16, 16);
    }
    p {
      margin: variables.em(14, 14) 0 variables.em(24, 14);
    }
  }
}

#disqus_thread {
  padding: 1em;
  position: relative;
  z-index: 4;
  background: white;
}

.notFound .coreContent {
  padding: 0 1em 1em;
}

.share-bar {
  margin-top: 5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-inline: 3px;
  flex-wrap: wrap;
  justify-content: center;
}
